*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Barlow", sans-serif !important;
}

html,
body,
#root {
  height: 100%;
  width: 100%;
}

.container,
.home_container {
  max-width: 1600px;
  padding-left: 1rem;
  padding-right: 1rem;
  margin-left: auto;
  margin-right: auto;
}

.home_container {
  max-width: 1200px;
}

// Custom scrollbar start
::-webkit-scrollbar {
  height: 10px;
  width: 10px;
}

::-webkit-scrollbar-track {
  background: #fff;
}

::-webkit-scrollbar-thumb {
  background: #737373b3;
}

::-webkit-scrollbar-thumb:hover {
  background: #737373ff;
}
// Custom scrollbar end

// No scrollbar start
.no_scrollbar::-webkit-scrollbar {
  display: none;
}

.no_scrollbar {
  -ms-overflow-style: none;
  scrollbar-width: none;
}
// No scrollbar end

// No arrows start
.no_arrows::-webkit-outer-spin-button,
.no_arrows::-webkit-inner-spin-button {
  appearance: none;
  margin: 0;
}

.no_arrows[type="number"] {
  appearance: textfield;
}
// No arrows end

// antd styles start
.ant-form-item-has-error {
  .ant-upload {
    border-color: #ff4d4f !important;
  }
}

.row_not_expandable {
  .ant-table-row-expand-icon {
    visibility: hidden;
  }
}
// antd styles end

@media (min-width: 768px) {
  .container,
  .home_container {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
}
